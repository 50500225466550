export const TIME_FORMAT_DATE = 'dd/MM/yyyy';
export const FORMAT_YEAR_MONTH_DATE = 'yyyy-MMM';
export const FORMAT_SHORT_MONTH_DATE = 'MMM';
export const TIME_FORMAT_DATE_TIME = `${TIME_FORMAT_DATE} HH:mm`;
export const TIME_FORMAT_DATE_EXCEL = 'yyyy-MM-dd';

export let PIPELINE_VARIABLES = {
  api: process.env.REACT_APP_API_SERVER as string,
  botBaseUrl: process.env.REACT_APP_BOT_BASE_URL as string,
  ptfBaseUrl: process.env.REACT_APP_PTF_BASE_URL as string,
};

export const PRODUCTS_PER_PAGE = 20;

export enum HELP_ME_LABEL {
  helpWithOtc = 'help-with-otc',
  helpWithVit = 'help-with-vit',
  helpWithSbot = 'help-with-sbot',
}

export enum FILTER_MEDICAL_CONDITIONS_TITLE_LABEL {
  filterTitleMedicalConditions = 'filter-title-medical-conditions',
  filterLifestyleTitle = 'filter-lifestyle-title',
}

export enum FILTER_MEDICAL_CONDITIONS_SUB_TITLE_LABEL {
  filterSubTitleMedicalConditions = 'filter-subtitle-medical-conditions',
  filterLifestyleSubtitle = 'filter-lifestyle-subtitle',
}

export enum FILTER_DIETARY_PREFERENCES_TITLE_LABEL {
  filterTitleDietaryPreferences = 'filter-title-dietary-preferences',
  filterDietaryPreferencesTitle = 'filter-dietary-preferences-title',
  filterPreferencesTitleSbot = 'filter-preferences-title-sbot',
}

export enum FILTER_DIETARY_PREFERENCES_SUB_TITLE_LABEL {
  filterSubTitleDietaryPreferences = 'filter-subtitle-dietary-preferences',
  filterDietaryPreferencesSubtitle = 'filter-dietary-preferences-subtitle',
  filterPreferencesSubtitleSbot = 'filter-preferences-subtitle-sbot',
}

export enum FILTER_DOSE_FORMS_TITLE_LABEL {
  filterDosageFormTitleSbot = 'filter-dosage-form-title-sbot',
}

export enum FILTER_DOSE_FORMS_SUB_TITLE_LABEL {
  filterDosageFormSubTitleSbot = 'filter-dosage-form-subtitle-sbot',
}

export enum CHOOSE_SCENARIO_TITLE_LABEL {
  chooseScenarioSbot = 'choose-scenario-sbot',
}

export enum CHOOSE_GENDER_TITLE_LABEL {
  chooseGenderTitleSbot = 'choose-gender-title-sbot',
}

export enum CHOOSE_GENDER_SUB_TITLE_LABEL {
  chooseGenderSubTitleSbot = 'choose-gender-subtitle-sbot',
}

export const FilterGridItemBreakPoints = {
  xs: 12,
  sm: 6,
  md: 4,
  lg: 3,
  xxl: 2.4,
};
