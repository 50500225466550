import { useMemo } from 'react';
import { apiBotCases } from 'services/bot-cases';
import { apiBotThemes } from 'services/bot-themes';
import { POSITION } from 'services/bot-user-profiles';
import { apiCountries } from 'services/countries';
import { apiDosageForms } from 'services/dosage-form';
import { apiGenders } from 'services/genders';
import { apiLanguages } from 'services/languages';
import { apiOrderStatuses } from 'services/order-statuses';
import { apiPharmaCompanies } from 'services/pharma-companies';
import { apiProductBrands } from 'services/product-brands';
import { apiProducts } from 'services/products';
import { apiProductsTailorFitThemes } from 'services/products-tailor-fit-themes';
import { apiTenants } from 'services/tenants';
import { fieldToLabelKey } from 'utils/other';
import { enumToArray } from 'utils/types';
import { useTranslate } from './use-translate';

type DataType<T> = T extends { data?: infer U } ? U : unknown;

export const useSourceData = <T extends { data?: any[] }>(v: T) => {
  const data = useMemo(() => {
    return (v.data || []) as Exclude<DataType<T>, undefined>;
  }, [v.data]);
  return useMemo(() => ({ ...v, data }), [v, data]);
};
export const useSourceLanguages = () => {
  return useSourceData(apiLanguages.useGetAllLanguagesQuery());
};
export const useSourceMajorLanguages = () => {
  return useSourceData(apiLanguages.useGetAllMajorLanguagesQuery());
};
export const useSourceBrands = () => {
  return useSourceData(apiProductBrands.useGetSourceProductBrandsQuery());
};
export const useSourceGenders = () => {
  return useSourceData(apiGenders.useGetSourceGendersQuery());
};
export const useSourceTenants = () => {
  return useSourceData(apiTenants.useGetSourceTenantsQuery());
};
export const useSourceProductByCompanyID = (
  pharmaCompanyID: string | undefined,
  countryID: string | undefined | null,
) => {
  return useSourceData(
    apiProducts.useGetSourceProductsByCompanyIDQuery(
      { pharmaCompanyID: pharmaCompanyID || '', countryID: countryID || '' },
      {
        skip: !pharmaCompanyID || !countryID,
      },
    ),
  );
};
export const useSourceDosageForms = () => {
  return useSourceData(apiDosageForms.useGetSourceDosageFormsQuery());
};
export const useSourceBotThemes = () => {
  return useSourceData(apiBotThemes.useGetSourceBotThemesQuery());
};
export const useSourceProductsTailorFitThemes = () => {
  return useSourceData(apiProductsTailorFitThemes.useGetSourceProductsTailorFitThemesQuery());
};
export const useSourceBotCases = ({ botID }: { botID: string }) => {
  return useSourceData(apiBotCases.useGetSourceBotCasesQuery(botID));
};
export const useSourceOrderStatuses = () => {
  return useSourceData(apiOrderStatuses.useGetSourceOrderStatusesQuery());
};
export const useSourcePharmaCompanies = () => {
  return useSourceData(apiPharmaCompanies.useGetSourcePharmaCompaniesQuery());
};

export const useSourceIconPosition = () => {
  const { t } = useTranslate();
  return useMemo(() => {
    return {
      data: enumToArray(POSITION).map((item) => ({
        ...item,
        title: t(fieldToLabelKey(item.title)),
      })),
    };
  }, [t]);
};

export const useSourceHighlightCountries = () => {
  return useSourceData(apiCountries.useGetSourceHighlightCountriesQuery());
};
