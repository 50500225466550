import { AxiosResponse } from 'axios';
import { DynamicResult } from 'modules/dynamic-service';
import * as dynamic from 'utils/dynamic';
import { API, apiRtk, rtkAdapterDynamicToSource, rtkAdapterError } from 'utils/service';

type Source = { id: string; title: string };
export const apiGenders = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getSourceGenders: build.query<Source[], void>({
      queryFn: async () => {
        try {
          const response = await API.api.gendersGetAllDynamicList({
            Select: dynamic.select('id', 'catalogName as title'),
            OrderBy: dynamic.orderBy('catalogName', 'asc'),
          });
          return rtkAdapterDynamicToSource(response as AxiosResponse<DynamicResult<Source>>);
        } catch (e: any) {
          return rtkAdapterError(e);
        }
      },
    }),
  }),
});
