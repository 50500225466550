import { AppLoading } from 'components/app-loading';

import { APP_ROUTES } from 'configs';
import { EmptyLayout, LoginLayout, PrivateLayout } from 'layouts';
import React from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';
import { RequireAuth } from 'router/requred-auth';
import { RequireTenant } from 'router/requred-tenant';

// dashboard
const PageDashboard = React.lazy(
  () => import(/* webpackChunkName: "dashboard" */ 'pages/dashboard'),
);

// bots
const PageBots = React.lazy(() => import(/* webpackChunkName: "bots" */ 'pages/bots'));
const PageProductsTailorFit = React.lazy(
  () => import(/* webpackChunkName: "products-tailor-fit" */ 'pages/products-tailor-fit'),
);

// products
const PageProducts = React.lazy(() => import(/* webpackChunkName: "products" */ 'pages/products'));
const PageProductsIgnored = React.lazy(
  () => import(/* webpackChunkName: "products-ignored" */ 'pages/products-ignored'),
);
const PageProductsAnalysis = React.lazy(
  () => import(/* webpackChunkName: "products-analysis" */ 'pages/products-analysis'),
);

// promotions
const PageOrders = React.lazy(() => import(/* webpackChunkName: "orders" */ 'pages/orders'));
const PageBoards = React.lazy(() => import(/* webpackChunkName: "boards" */ 'pages/boards'));
const PagePromotionAnalytics = React.lazy(
  () => import(/* webpackChunkName: "products-analysis" */ 'pages/promotion-analytics'),
);

// session
const PageSessions = React.lazy(() => import(/* webpackChunkName: "sessions" */ 'pages/sessions'));

// settings
const PageThemes = React.lazy(() => import(/* webpackChunkName: "themes" */ 'pages/bot-themes'));
const PageProductsTailorFitThemes = React.lazy(
  () =>
    import(/* webpackChunkName: "products-tailor-fit-theme" */ 'pages/products-tailor-fit-themes'),
);
const PageLabelsForBot = React.lazy(
  () => import(/* webpackChunkName: "labels-for-bot" */ 'pages/labels-for-bot'),
);
const PageLabelsForProductsTailorFit = React.lazy(
  () =>
    import(
      /* webpackChunkName: "labels-for-products-tailor-fit" */ 'pages/labels-for-products-tailor-fit'
    ),
);
const PageLabelsForCustomerPortal = React.lazy(
  () =>
    import(/* webpackChunkName: "labels-for-customer-portal" */ 'pages/labels-for-customer-portal'),
);

// rest
const HomePage = React.lazy(() => import(/* webpackChunkName: "home" */ 'pages/home'));
const LoginPage = React.lazy(() => import(/* webpackChunkName: "home" */ 'pages/login'));
const LoginCodePage = React.lazy(() => import(/* webpackChunkName: "home" */ 'pages/login-code'));
const LoginTenantPage = React.lazy(
  () => import(/* webpackChunkName: "home" */ 'pages/login-tenant'),
);

const NotFoundPage = React.lazy(
  () => import(/* webpackChunkName: "not-found" */ 'pages/not-found'),
);

export const routes = createBrowserRouter([
  {
    element: (
      <RequireAuth>
        <RequireTenant>
          <PrivateLayout />
        </RequireTenant>
      </RequireAuth>
    ),
    children: [
      {
        path: APP_ROUTES.DASHBOARD.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageDashboard />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.BOTS.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageBots />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.PRODUCTS_TAILOR_FIT.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageProductsTailorFit />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.PRODUCTS.path,
        children: [
          {
            index: true,
            element: <Navigate to={APP_ROUTES.PRODUCTS_LIST.path} replace />,
          },
          {
            path: APP_ROUTES.PRODUCTS_LIST.path,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <PageProducts />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.PRODUCTS_IGNORED.path,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <PageProductsIgnored />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.PRODUCTS_ANALYST.path,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <PageProductsAnalysis />
              </React.Suspense>
            ),
          },
        ],
      },
      {
        path: APP_ROUTES.PROMOTION.path,
        children: [
          {
            index: true,
            element: <Navigate to={APP_ROUTES.PROMOTION_ORDERS.path} replace />,
          },
          {
            path: APP_ROUTES.PROMOTION_ORDERS.path,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <PageOrders />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.PROMOTION_BOARS.path,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <PageBoards />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.PROMOTION_ANALYTICS.path,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <PagePromotionAnalytics />
              </React.Suspense>
            ),
          },
        ],
      },
      {
        path: APP_ROUTES.SESSIONS.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageSessions />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.SETTINGS.path,
        children: [
          {
            index: true,
            element: <Navigate to={APP_ROUTES.SETTINGS_THEMES.path} replace />,
          },
          {
            path: APP_ROUTES.SETTINGS_PRODUCTS_TAILOR_FIT_THEME.path,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <PageProductsTailorFitThemes />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.SETTINGS_THEMES.path,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <PageThemes />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.SETTINGS_LABELS_FOR_BOT.path,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <PageLabelsForBot />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.SETTINGS_LABELS_FOR_PRODUCTS_TAILOR_FIT.path,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <PageLabelsForProductsTailorFit />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.SETTINGS_LABELS_FOR_CUSTOMER_PORTAL.path,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <PageLabelsForCustomerPortal />
              </React.Suspense>
            ),
          },
        ],
      },
      {
        path: '*',
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <NotFoundPage />
          </React.Suspense>
        ),
      },
    ],
  },
  {
    element: <LoginLayout />,
    children: [
      {
        path: APP_ROUTES.LOGIN.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <LoginPage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.LOGIN_CODE.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <LoginCodePage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.LOGIN_TENANT.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <RequireAuth>
              <LoginTenantPage />
            </RequireAuth>
          </React.Suspense>
        ),
      },
    ],
  },
  {
    element: <EmptyLayout />,
    children: [
      {
        path: APP_ROUTES.HOME.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <HomePage />
          </React.Suspense>
        ),
      },
      {
        path: '*',
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <NotFoundPage />
          </React.Suspense>
        ),
      },
    ],
  },
]);
