import {
  AddShoppingCart,
  Android,
  DashboardOutlined,
  Gavel,
  Loyalty,
  Settings,
  Timeline,
} from '@mui/icons-material';
import { Collapse, Typography, TypographyProps } from '@mui/material';
import clsx from 'clsx';
import { NativeScroll } from 'components/native-scroll';
import { APP_ROUTES } from 'configs';
import { useAppDispatch, useTranslate } from 'hooks';
import React, { useCallback, useMemo } from 'react';
import { actionAccountLogout } from 'store/auth';
import { NavigationItem, NavigationOption } from '../navigation-item';
import style from './index.module.scss';

interface CategoryTitleProps extends TypographyProps {}
const CategoryTitle: React.FC<CategoryTitleProps> = (props) => {
  return (
    <Typography
      color={'textSecondary'}
      variant={'body2'}
      fontWeight={600}
      textTransform={'capitalize'}
      pl={'2.4rem'}
      pr={'2.4rem'}
      {...props}
    />
  );
};
interface Props {
  isMenuOpen: boolean;
  onMenuClose?: () => void;
  onMenuOpen?: () => void;
}

export const NavigationList: React.FC<Props> = ({ isMenuOpen, onMenuOpen, onMenuClose }) => {
  const { tp } = useTranslate();
  const dispatch = useAppDispatch();
  const onLogout = useCallback(() => {
    dispatch(actionAccountLogout());
  }, [dispatch]);

  const optionsOverview = useMemo<NavigationOption[]>(() => {
    return [
      {
        title: tp('dashboard'),
        Icon: <DashboardOutlined color={'inherit'} fontSize={'inherit'} />,
        to: APP_ROUTES.DASHBOARD().config,
      },
      {
        title: tp('sessions'),
        Icon: <Timeline color={'inherit'} fontSize={'inherit'} />,
        to: APP_ROUTES.SESSIONS().config,
      },
    ];
  }, [tp]);

  const optionsManagement = useMemo<NavigationOption[]>(() => {
    return [
      {
        title: tp('products'),
        Icon: <AddShoppingCart color={'inherit'} fontSize={'inherit'} />,
        options: [
          { title: tp('products-list'), to: APP_ROUTES.PRODUCTS_LIST().config },
          { title: tp('ignored-products'), to: APP_ROUTES.PRODUCTS_IGNORED().config },
          { title: tp('products-analysis'), to: APP_ROUTES.PRODUCTS_ANALYST().config },
        ],
      },
      {
        title: tp('promotion'),
        Icon: <Gavel color={'inherit'} fontSize={'inherit'} />,
        options: [
          { title: tp('orders'), to: APP_ROUTES.PROMOTION_ORDERS().config },
          { title: tp('boards'), to: APP_ROUTES.PROMOTION_BOARS().config },
          { title: tp('promotion-analytics'), to: APP_ROUTES.PROMOTION_ANALYTICS().config },
        ],
      },
    ];
  }, [tp]);

  const optionsConfiguration = useMemo<NavigationOption[]>(() => {
    return [
      {
        title: tp('bots'),
        Icon: <Android color={'inherit'} fontSize={'inherit'} />,
        to: APP_ROUTES.BOTS().config,
      },
      {
        title: tp('products-tailor-fit'),
        Icon: <Loyalty color={'inherit'} fontSize={'inherit'} />,
        to: APP_ROUTES.PRODUCTS_TAILOR_FIT().config,
      },
      {
        title: tp('settings'),
        Icon: <Settings color={'inherit'} fontSize={'inherit'} />,
        options: [
          { title: tp('themes'), to: APP_ROUTES.SETTINGS_THEMES().config },
          {
            title: tp('products-tailor-fit-themes'),
            to: APP_ROUTES.SETTINGS_PRODUCTS_TAILOR_FIT_THEME().config,
          },
          { title: tp('labels-for-bot'), to: APP_ROUTES.SETTINGS_LABELS_FOR_BOT().config },
          {
            title: tp('labels-for-customer-portal'),
            to: APP_ROUTES.SETTINGS_LABELS_FOR_CUSTOMER_PORTAL().config,
          },
          {
            title: tp('labels-for-products-tailor-fit'),
            to: APP_ROUTES.SETTINGS_LABELS_FOR_PRODUCTS_TAILOR_FIT().config,
          },
          { title: tp('log-out'), onClick: onLogout },
        ],
      },
    ];
  }, [tp, onLogout]);

  return (
    <NativeScroll mode={'always'} size={'small'} sx={{ overflowX: 'hidden' }}>
      <div className={clsx(style.menuHelper, isMenuOpen && style.hide)} />
      <Collapse in={isMenuOpen}>
        <CategoryTitle pt={'1.4rem'} pb={'1rem'}>
          {tp('overview')}
        </CategoryTitle>
      </Collapse>
      {optionsOverview.map((option, i) => {
        return (
          <NavigationItem
            key={i}
            Icon={option.Icon}
            options={option.options}
            title={option.title}
            isMenuOpen={isMenuOpen}
            onMenuOpen={onMenuOpen}
            onMenuClose={onMenuClose}
            to={option.to}
            level={1}
          />
        );
      })}
      <Collapse in={isMenuOpen}>
        <CategoryTitle pt={'2rem'} pb={'1rem'}>
          {tp('management')}
        </CategoryTitle>
      </Collapse>
      {optionsManagement.map((option, i) => {
        return (
          <NavigationItem
            key={i}
            Icon={option.Icon}
            options={option.options}
            title={option.title}
            isMenuOpen={isMenuOpen}
            onMenuOpen={onMenuOpen}
            onMenuClose={onMenuClose}
            to={option.to}
            level={1}
          />
        );
      })}
      <Collapse in={isMenuOpen}>
        <CategoryTitle pt={'2rem'} pb={'1rem'}>
          {tp('configuration')}
        </CategoryTitle>
      </Collapse>
      {optionsConfiguration.map((option, i) => {
        return (
          <NavigationItem
            key={i}
            Icon={option.Icon}
            options={option.options}
            title={option.title}
            isMenuOpen={isMenuOpen}
            onMenuOpen={onMenuOpen}
            onMenuClose={onMenuClose}
            to={option.to}
            onClick={option.onClick}
            level={1}
          />
        );
      })}
    </NativeScroll>
  );
};
