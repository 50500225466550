import { AxiosResponse } from 'axios';
import { DynamicResult } from 'modules/dynamic-service';
import {
  contains,
  decoratorIsNotNullable,
  dynamicNamespace,
  equals,
  mergeFilters,
} from 'utils/dynamic';
import {
  API,
  apiRtk,
  makePatchArgs,
  rtkAdapterDynamicItem,
  rtkAdapterDynamicToSource,
  rtkAdapterError,
} from 'utils/service';
import { PatchPartial } from 'utils/types';
import { IBotTheme, IProductsTailorFitTheme } from '__generated__/api';
import { IGridProductsTailorFitTheme, IGridProductsTailorFitThemeInput } from './models';

const REVALIDATE_KEY = 'ProductsTailorFitThemes' as const;

const dynamic = dynamicNamespace<IProductsTailorFitTheme>();

const requestGet = API.api.productsTailorFitThemesGetAllDynamicList;
const requestPost = API.api.productsTailorFitThemesCreateCreate;
const requestPatch = API.api.productsTailorFitThemesPatchPartialUpdate;
const requestDelete = API.api.productsTailorFitThemesDeleteDelete;

type Source = Pick<IProductsTailorFitTheme, 'id' | 'title'>;
export const apiProductsTailorFitThemes = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getSourceProductsTailorFitThemes: build.query<Array<Source>, void>({
      queryFn: async () => {
        try {
          const response = await requestGet({
            Select: dynamic.select('id', 'title'),
            Filter: mergeFilters(dynamic.makeFilter('isActive', true, equals)).join('&&'),
            OrderBy: dynamic.orderBy('title', 'asc'),
          });
          return rtkAdapterDynamicToSource(response);
        } catch (e: any) {
          return rtkAdapterError(e);
        }
      },
    }),

    getGridProductsTailorFitThemes: build.query<
      DynamicResult<IGridProductsTailorFitTheme>,
      IGridProductsTailorFitThemeInput
    >({
      queryFn: async ({ search, take: Take, skip: Skip, orderBy, isActive }) => {
        try {
          const response = await requestGet({
            Filter: mergeFilters(
              dynamic.makeFilter(['title', 'description'], search, contains),
              dynamic.makeFilter('isActive', isActive, decoratorIsNotNullable(equals)),
            ).join('&&'),
            Select: dynamic.select('id', 'title', 'description', 'isActive'),
            OrderBy: dynamic.orderBy(orderBy),
            Take,
            Skip,
            Count: true,
          });
          return response as AxiosResponse<DynamicResult<IGridProductsTailorFitTheme>>;
        } catch (e: any) {
          return rtkAdapterError(e);
        }
      },
      providesTags: [{ type: REVALIDATE_KEY }],
    }),

    getProductsTailorFitTheme: build.query<IProductsTailorFitTheme, string>({
      queryFn: async (id) => {
        try {
          const result = await requestGet({
            Filter: dynamic.makeFilter('id', id, equals),
            Take: 1,
          });
          return rtkAdapterDynamicItem(result);
        } catch (e: any) {
          return rtkAdapterError(e);
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_KEY, id }],
    }),
    postProductsTailorFitTheme: build.mutation<
      IProductsTailorFitTheme,
      Omit<IProductsTailorFitTheme, 'id'>
    >({
      queryFn: async (input: Partial<IProductsTailorFitTheme>) => {
        try {
          const { data } = await requestPost(input);
          return { data };
        } catch (e: any) {
          return rtkAdapterError(e);
        }
      },
      invalidatesTags: [{ type: REVALIDATE_KEY }],
    }),
    patchProductsTailorFitTheme: build.mutation<void, PatchPartial<IProductsTailorFitTheme, 'id'>>({
      queryFn: async (input) => {
        try {
          await requestPatch(...makePatchArgs(input));
          return { data: undefined };
        } catch (e: any) {
          return rtkAdapterError(e);
        }
      },
      invalidatesTags: (result, error, data) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id: String(data?.id) },
      ],
    }),
    deleteProductsTailorFitTheme: build.mutation<void, PatchPartial<IBotTheme, 'id'>>({
      queryFn: async (input) => {
        try {
          await requestDelete(String(input.id));
          return { data: undefined };
        } catch (e: any) {
          return rtkAdapterError(e);
        }
      },
      invalidatesTags: (result, error, data) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id: String(data?.id) },
      ],
    }),
  }),
});

export * from './models';
