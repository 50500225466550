import { AxiosResponse } from 'axios';
import { SourceModel } from 'configs/types';
import { DynamicResult } from 'modules/dynamic-service';
import { dynamicNamespace, equals, mergeFilters } from 'utils/dynamic';
import {
  API,
  apiRtk,
  rtkAdapterDynamicItem,
  rtkAdapterDynamicToSource,
  rtkAdapterError,
} from 'utils/service';
import { ITenant } from '__generated__/api';

const dynamic = dynamicNamespace<ITenant>();

type TenantCountry = { country: string; countryID: string };
export const apiTenants = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getSourceTenants: build.query<Array<SourceModel>, void>({
      queryFn: async () => {
        try {
          const response = await API.api.tenantsGetAllDynamicList({
            Select: dynamic.select('id', 'companyName as title', 'isActive'),
            Filter: mergeFilters(dynamic.makeFilter('isActive', true, equals)).join('&&'),
            OrderBy: dynamic.orderBy('companyName', 'asc'),
          });
          return rtkAdapterDynamicToSource(
            response as unknown as AxiosResponse<DynamicResult<SourceModel>>,
          );
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
    getTenantCountry: build.query<TenantCountry, string>({
      queryFn: async (tenantID) => {
        try {
          const response = await API.api.tenantsGetAllDynamicList({
            Select: dynamic.select('country.countryName as country, country.id as countryID'),
            Filter: mergeFilters(
              dynamic.makeFilter('isActive', true, equals),
              dynamic.makeFilter('id', tenantID, equals),
            ).join('&&'),
            Take: 1,
          });
          return rtkAdapterDynamicItem(
            response as unknown as AxiosResponse<DynamicResult<TenantCountry>>,
          );
        } catch (e: any) {
          return rtkAdapterError(e);
        }
      },
    }),
  }),
});
