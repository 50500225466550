import { replaceSpace } from 'utils/other';
import * as yup from 'yup';
import { ILabel, ILabelDynamicResponse } from '__generated__/api';

export const schemaLabels = yup.object({
  title: yup.string().required('rule-required'),
  labelKey: yup.string().required('rule-required').transform(replaceSpace),
  languageID: yup.string().required('rule-required'),
  isActive: yup.boolean().required('rule-required'),
});

export interface IGridLabel extends Pick<ILabel, 'id' | 'title' | 'labelKey' | 'isActive'> {
  languageTitle: string;
}

export interface IGridLabelResponse extends Omit<ILabelDynamicResponse, 'items'> {
  items: IGridLabel[];
}

export enum LABEL_SOURCE_ID {
  BOT = '280a41e3-1bc2-4cac-a644-087acc4c6859',
  CUSTOMER_PORTAL = '3587e9b1-6d36-45ec-84e8-8d3cfc736d37',
  PRODUCTS_TAILOR_FIT = 'faf79eb5-70ab-4b78-bb66-63b0e432ef71',
}
